import React from 'react';

export default function TextSummarizer({ url }) {
    const [text, setText] = React.useState('');
    const [message, setMessage] = React.useState([Array(1).fill(null)]);
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    const handleAction = () => {
      if (!text) {
        setMessage([{ model: 'Error', message: 'Please enter some text' }]);
        return;
      }
  
      const formData = new FormData();
      formData.append('input_doc', text);
    
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setMessage(result);
        })
        .catch((error) => {
          setMessage([{ model: 'Error', message: 'An error occurred while summarizing the text' }]);
        });
    };
  
    const models = new Array(message.length);
    for(let i = 0; i < message.length; i++) {
      models[i] = <ModelOutput key={i} model={message[i].model} message={message[i].message} />;
    }
  
    return (
      <div class="summarizer">
        <h2>Summarize Text</h2>
        <textarea value={text} onChange={handleTextChange} />
        <button onClick={handleAction}>Summarize</button>
        <div>{models}</div>
      </div>
    );
  }
  
  function ModelOutput({ model, message }) {
    return (
      <div>
        <h3>{model}</h3>
        <p class="output">{message}</p>
      </div>
    );
  }