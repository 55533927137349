import React from 'react';

export default function FileUploader({ url }) {
    const [file, setFile] = React.useState(null);
    const [message, setMessage] = React.useState('');
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };

    const handleUpload = () => {
      if (!file) {
        setMessage('Please select a file to upload');
        return;
      }

      setMessage('');
  
      const formData = new FormData();
      formData.append('file', file);
  
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setMessage(result.message);
        })
        .catch((error) => {
          setMessage('An error occurred while uploading the file');
        });
    };
  
    return (
      <div class="fileUploader">
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        <p class="output">{message}</p>
      </div>
    );
  
  }