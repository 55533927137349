// adapted from https://jsyang.ca/guides/handling-sse-streaming-ai-models/

export const readEventStream = async (url, formData, onMessage, onProcessing) => {
    const EVENTSTREAM_PREFIX = 'data: ';
    const EVENTSTREAM_END_LINE = '}';
    const EVENTSTREAM_ENDED = '[DONE]';

    onMessage('');
    onProcessing(true);

    try {
      console.log('before fetch');
      const res = await fetch(url, {
          method: 'POST',
          body: formData
      });

      // Use a ReadableStream to handle the response
      const reader = res.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let totalMsg = '';
      let buffer = '';

      while (true) {
          // Handle each chunk
          let { value, done } = await reader.read();
          
          if(done) break;

          if (buffer) {
              value = buffer + value;
              buffer = '';
          }

          // when true dumps remaining iterations into the buffer (technically should never happen)
          let forceBuffer = false;

          // loop through all the lines in the chunk
          for (const line of value.split('\n')) {
              // Skip empty lines
              if (!line) continue;

              // if the current line contains data, then process it
              if (line.indexOf(EVENTSTREAM_PREFIX) >= 0) {
                  // skip the prefix
                  const msgChunk = line.substring(EVENTSTREAM_PREFIX.length);

                  console.log('msgChunk: ', msgChunk);

                  // correctly formatted lines will end as a JSON object, if not or forced, buffer the line
                  if(forceBuffer || !msgChunk.endsWith(EVENTSTREAM_END_LINE)) {
                    buffer += line;

                    // one incomplete line means anything else is incorrect, so buffer everything else
                    forceBuffer = true;
                    continue;
                  }

                  // if the line is the end of the event stream, then we are done
                  if (msgChunk === EVENTSTREAM_ENDED) {
                    console.log('DONE!');
                    done = true;
                    break;
                  } else {
                      // process the line by getting the response and updating the message
                      const { response } = JSON.parse(msgChunk);
                      console.log('response: ', response);
                      totalMsg += response;
                      onMessage(totalMsg);
                  }
              }
              else {
                  // incomplete line, add to buffer
                  buffer += line;
              }
          }

          // done, so exit loop
          if (done) break;
      }

      // clean up, close the stream from the client-side, and update the final message
      reader.cancel();
      onMessage(totalMsg);
    } catch (e) {
        console.trace(e);
        onProcessing(false);
    }

    // end procressing
    onProcessing(false);
  };