import React from 'react';
import { StytchLogin } from '@stytch/react';
import { Products } from '@stytch/vanilla-js';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

Customize at https://www.authkit.dev/

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
https://stytch.com/docs/sdks/javascript-sdk/ui-config
*/
const Login = () => {
  const styles = {
    container: {
      width: '100%',
    },
    buttons: {
      primary: {
        backgroundColor: '#4A37BE',
        borderColor: '#4A37BE',
      },
    },
  };

  const callbacks = {
    onEvent: ({ type, data }) => {
      if (type === 'MAGIC_LINK_LOGIN_OR_CREATE') {
        console.log('Email magic Link sent', data);
      }
    },
    onError: (data) => {
      console.log(data);
    },
  };

  const authUrl = process.env.REACT_APP_AUTH_CALLBACK_URL;

  const config = {
    products: [Products.emailMagicLinks],
    emailMagicLinksOptions: {
      loginRedirectURL: authUrl,
      loginExpirationMinutes: 60,
      signupRedirectURL: authUrl,
      signupExpirationMinutes: 60,
    },
  };

  return <div class="login"><StytchLogin config={config} styles={styles} callbacks={callbacks} /></div>;
};

export default Login;