import React from 'react';
import { useNavigate } from 'react-router-dom';

// see for how to do redirects to a page during login flow: https://stytch.com/docs/guides/dashboard/redirect-urls, must make corresponding change in Login.js


export default function Authenticate() {

    const navigate = useNavigate();

    const handleRedirect = () => {
      // Logic for redirection
      navigate('/');
    };

    setTimeout(() => {navigate('/');}, 1000);

    return (
        <button onClick={handleRedirect}>Redirect</button>
    );
}