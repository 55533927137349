import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// stytch
import { useStytchUser } from "@stytch/react";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Home from "./components/Home";
import PageNotFound from "./components/PageNotFound";
import Authenticate from "./components/Authenticate";

function App() {
  const baseUrl = process.env.REACT_APP_API_SERVER_URL;

  // The useStytchUser hook will return the existing Stytch User object if one exists
  const { user } = useStytchUser();

  return (
    <BrowserRouter>
    {user ? (
      <Routes>
        <Route path="/" element={<Home baseUrl={baseUrl} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="*" element={<PageNotFound />} />
        </Routes>
    ) : (
      <Routes>
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="*" element={<Login />} />
      </Routes>
    )
    }
    </BrowserRouter>
  );
}

export default App;
