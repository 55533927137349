import React from 'react';
import FileUploader from './FileUploader';
import TextAnalyzer from './TextAnalyzer';
import TextSummarizer from './TextSummarizer';

export default function Home({ baseUrl }) {

return (
        <div className="App">
            <header className="App-header">
                
            </header>
            <FileUploader url={ baseUrl + '/upload' } />
            <TextSummarizer url={ baseUrl + '/summary' } />
            <TextAnalyzer url={ baseUrl + '/analyze' } />
        </div>
    );
}