import React from 'react';
import { readEventStream } from '../utils';

export default function TextAnalyzer({ url }) {
    const [text, setText] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [processing, setProcessing] = React.useState(false);
  
    const handleTextChange = (event) => {
      setText(event.target.value);
    };
  
    const handleAction = async () => {
      try {
        const formData = new FormData();
        formData.append('input_analyze', text);

        readEventStream(url, formData, setMessage, setProcessing);
      } catch (e) {
          console.trace(e);
      }
    };
  
    return (
      <div class="analyzer">
        <h2>Analyze Text</h2>
        <p><textarea value={text} onChange={handleTextChange} /></p>
        <button onClick={handleAction} disabled={processing}>Analyze</button>
        <div class="output">{message}</div>
      </div>
    );
  }